import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import Theme from 'components/theme/global-style'
import useFetchUser from 'features/auth/hooks/useFetchUser'

import React, { lazy, Suspense } from 'react'
import { LineWave } from 'react-loader-spinner'
import { ThemeProvider } from 'styled-components'
import { useUser } from '../features/auth/store'
import './style.css'
import { Container, SpinDiv } from './styles'

const Authenticated = lazy(() => import('./Authenticated'))
const Unauthenticated = lazy(() => import('./Unauthenticated'))

// 2. Extend the theme to include custom colors, fonts, etc
const breakpoints = {
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1048px',
  '2xl': '1536px',
}
const theme = extendTheme({ breakpoints })
const App = () => {
  const { userData, dispatchUser, isLoggedIn } = useUser()
  useFetchUser(userData, dispatchUser)

  return (
    <Container>
      <Suspense
        fallback={
          <SpinDiv>
            <LineWave color="#006CAE" />
          </SpinDiv>
        }
      >
        {isLoggedIn ? (
          <Authenticated
            isLoggedIn={isLoggedIn}
            dispatch={dispatchUser}
            userData={userData}
          />
        ) : (
          <Unauthenticated />
        )}
      </Suspense>
    </Container>
  )
}

export default () => (
  <ThemeProvider theme={theme}>
    <Theme />
    <ChakraProvider resetCSS>
      <App />
    </ChakraProvider>
  </ThemeProvider>
)
