import { logger } from 'helpers/logger'
import { useLocalStorage } from 'react-use'
import { create } from 'zustand'

const userAtom = create((set) => ({
  user: null,
  isLoggedIn: false,
  dispatchUser: ({ type, payload }) => {
    switch (type) {
      case 'SAVE_USER':
        set({ user: payload, isLoggedIn: true })
        break
      case 'LOGOUT':
        set({ user: null, isLoggedIn: false })
        break
      default:
        logger.error('Invalid action type')
        break
    }
  },
}))

export const useUser = () => {
  const { user, isLoggedIn, dispatchUser } = userAtom();

  useLocalStorage('user-token', user?.token?.toString());

  return { user, isLoggedIn, dispatchUser, userData: userAtom.getState() }
}
