import { useState, useEffect, useCallback } from 'react'
import { adapter } from '../../../config'

export default (userData, dispatch) => {
  const [loading, setLoading] = useState(true)

  const fetchUser = useCallback(async () => {
    try {
      const { data, status } = await adapter.get('/me?$jwt=true')

      if (status === 200) {
        dispatch({ type: 'SAVE_USER', payload: data });
      } else {
        dispatch({ type: "LOGOUT" })
      }
      setLoading(false)
    } catch (err) {
      //
      setLoading(false)
    }
  }, [dispatch])

  useEffect(() => {
    if (!userData.isLoggedIn) {
      fetchUser()
    }
  }, [userData.isLoggedIn, fetchUser])

  return {
    loading,
    isLoggedIn: userData.isLoggedIn,
  }
}
